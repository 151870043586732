body {
  font-family: Arial, sans-serif;
  background-color: #1e1e1e;
}

div {
  margin: 20px;
}

select {
  padding: 10px;
  font-size: 1rem;
}

#bold{
  font-weight: 700;
}

#address{
  color:yellow;
}

h1 {
  text-align: center;
  font-size: 3rem; /* Adjust size as needed */
  font-weight: bold; /* Makes the text bold */
  color: #fff; /* Change to a color that matches your theme */
  margin-top: 20px;
}
/* Center align the header content and style the spacing */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
}

/* Style for the logo image */
.logo {
  width: 100px; /* Adjust size as needed */
  height: 100px;
  object-fit: cover; /* Ensures logo scales properly */
}

/* Style for the h1 */
.header h1 {
  font-size: 2rem; /* Adjust size as needed */
  color: #333; /* Update color as per design */
  margin: 0;
  font-weight: bold;
}

.big{
  font-size:14px;
}
.contact{
  text-decoration: none;
  color:#fff;
  font-size:24px;
  font-weight: 600;

}
.white{
  color:#fff;
  font-size:36px;
  text-align: center;
}
.white-list{
  color:#fff;
  font-size: 36px;
  text-align: center;
  list-style: none;
}

.sale-item {
  border-bottom: 2px solid #ddd; /* Light grey border */
  padding: 20px 0; /* Adds space inside the sale item */
  margin-bottom: 20px; /* Adds space between items */
  font-family: Arial, sans-serif; /* Ensures a clean font */
}

.sale-item h3 {
  font-size: 30px;
  margin-bottom: 5px;
  color: #000; /* Makes the address stand out */
}

.sale-item p {
  font-size: 24px;
  color: #fff; /* A slightly muted color for additional details */
}

.no{
  font-size:28px;
  color:#fff;
  text-align: center;
  margin-top:50px;
}

.footer{
  color: white;
  font-size:24px;
  text-align: center;
  margin-top:200px;
}

.navbar{
  margin-bottom: 100px;
}
.contact:hover{
  color:aqua;
}
.lime{
  color:lime;
}
.aqua{
  color:aqua;
}

.first-monday{
  width:25%;
  height:auto,
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}

/* Further adjustments for extra-small screens */
@media (max-width: 480px) {
  h3 {
    font-size: 1.25rem;
    line-height: 1.3;
  }

  p {
    font-size: 0.85rem;
    line-height: 1.4;
  }
}
